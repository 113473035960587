<template>
  <div>
    <div class="container d-block d-lg-none" style="padding: 0; margin: 0">
      <div
        id="wx-bg"
        style="
          display: none;
          position: absolute;
          background-color: #000000;
          opacity: 0.9;
          width: 100%;
          height: 100%;
        "
      ></div>
      <img
        id="wx-msg-android"
        src="../assets/img/img-02.png"
        style="display: none; position: absolute; float: right; right: 0"
        class="img-fluid"
      />
      <img
        id="wx-msg-ios"
        src="../assets/img/img-03.png"
        style="display: none; position: absolute; float: right; right: 0"
        class="img-fluid"
      />
      <div class="">
        <div class="text-center p-0 m-0" style="border: solid 0px #fff">
          <img
            src="../assets/img/download-content-mobile.png"
            style="width: 90%; padding: 10px 0 20px 0"
            class="img-fluid"
          />
          <a id="btn-url" href="/">
            <img
              src="../assets/img/dl-button.png"
              style="width: 90%; padding: 0px 20px 0px 20px"
              class="img-fluid"
            />
          </a>
          <!-- <button class="btn btn-danger">
              <i class="fa fa-backspace"></i> Back
            </button> -->
        </div>
      </div>
    </div>

    <div class="container-fluid d-none d-lg-block" style="background: #1b243d">
      <div class="container d-none d-lg-block">
        <div class="row pb-3">
          <div class="col-lg-6"></div>
          <div class="col-lg-6 text-right mt-3">
            <a
              style="font-size: 18px; color: white"
              :href="`/${i18n.locale}`"
              >{{ $t("nav.home-page") }}</a
            >
            <a
              href="/download-app"
              style="
                font-size: 18px;
                margin-left: 30px;
                margin-right: 50px;
                text-decoration: underline;
                text-underline-position: under;
                color: #fff;
              "
              >{{ $t("nav.download-app") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container d-none d-lg-block">
      <div class="">
        <div class="row">
          <div class="col-lg-8" style="margin-top: 30px">
            <img
              src="../assets/img/download-content-01.png"
              class="img-fluid"
            />
          </div>
          <div class="col-lg-4">
            <div style="margin-top: 250px">
              <div style="margin: 20px 80px">
                <img
                  :src="
                    qrCode ? qrCode.path : require('../assets/img/qr-code.png')
                  "
                  alt=""
                  style="width: 220px; height: 220px"
                />
              </div>
              <div>
                <a id="btn-download-pc" href="/">
                  <img
                    src="../assets/img/dl-button.png"
                    style="width: 260px; margin-left: 60px; margin-top: 30px"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import i18n from "../i18n";

export default {
  name: "Download",
  data() {
    return {
      i18n,
      isIosDevice: false,
      matchSetting: null,
      bannerHost: "",
      bannerSettings: [
        {
          title: "pc qr",
          category: 17,
          status: 1,
          target_url: "https://978bet36.com/",
          cat_title: "电脑-QR",
          path: "https://72963.com/uploads/images/20201214/5696d802b494e7f762dac27e22f08513.gif",
          width: 970,
          height: 150,
        },
      ],
      currentHost: window.location.hostname,
    };
  },
  props: {
    msg: String,
  },
  created() {
    var currentLocale = window.location.pathname.split("/")[1];

    if (localStorage.getItem("lang") != null) {
      currentLocale = localStorage.getItem("lang");
      this.$router.push({
        params: { lang: currentLocale },
      });
    }

    this.$i18n.locale = currentLocale;
    axios
      .get(`${this.bannerHost}/api/banner`)
      .then((response) => {
        const axiosBannerList = response.data.data;
        this.bannerSettings = axiosBannerList;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
  computed: {
    qrCode() {
      if (this.bannerSettings != null) {
        let qr = this.bannerSettings.filter(function (item) {
          return item.category == 17;
          //return item;
        });
        return qr[0] || "";
      } //

      return "";
    },
    whatsappLink() {
      var text = "whatsapp://send?phone=";
      var number =
        this.matchSetting && this.matchSetting.whatsapp_number
          ? this.matchSetting.whatsapp_number
          : "";
      return text + number;
    },
  },
  mounted() {
    //Api setting detail

    var URL_ANDROID = "https://app.72963.com/72963.apk";
    var URL_IOS = "https://apps.apple.com/cn/app/id1540951520";
    var URL_PC = "https://app.72963.com/72963.apk";

    var u = navigator.userAgent;
    var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    this.isIosDevice = isiOS;
    var ua = window.navigator.userAgent.toLowerCase();
    var isWeiXin = ua.match(/MicroMessenger/i) == "micromessenger";

    if (isWeiXin) {
      document.getElementById("wx-bg").style.display = "block";
      if (isiOS) {
        document.getElementById("wx-msg-ios").style.display = "block";
      }
      if (isAndroid) {
        document.getElementById("wx-msg-android").style.display = "block";
      }
    } //

    if (isAndroid) {
      document.getElementById("btn-url").href = URL_ANDROID;
    } else if (isiOS) {
      document.getElementById("btn-url").href = URL_IOS;
    } else {
      document.getElementById("btn-download-pc").href = URL_PC;
      //window.location.href = URL_DESKTOP_OTHER;
    }

    var w =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (w < 992) {
      let bodyElem = document.getElementsByTagName("BODY")[0];
      bodyElem.classList.add("bg-mobile");
    } else {
      let bodyElem = document.getElementsByTagName("BODY")[0];
      bodyElem.classList.add("bg-pc");
    }
    axios
      .get(`/api/setting-detail`)
      .then((response) => {
        this.matchSetting = response.data.data;
      })
      .catch((error) => console.log(error))
      .finally(() => {});
  },
};
</script>

<style scope>
.bg-pc {
  background: url("../assets/img/bg-pc.jpg");
  background-size: cover;
  background-position: center center;
}
.bg-mobile {
  background: url("../assets/img/bg-mobile.jpg");
  background-size: cover;
  background-position: center center;
}
</style>
